<template>
  <div class="com-item">
    <div class="m-title">
      <!-- 智能合约 -->
      {{ $t("study.contT2") }}
    </div>
    <div class="m-p">
      <!-- 智能合约是PSC应用程序的基本构建块。它们是存储在区块链上的计算机程序，允许我们将传统合约转换为数字平行。智能合约非常合乎逻辑——遵循
        if this then that 结构。这意味着它们的行为与编程完全一致，并且无法更改。 -->

      {{ $t("study.contP") }}
    </div>
    <div class="m-but2" @click="uti.goPath(store.link.study.contBut)">
      <!-- 部署智能合约 -->
      {{ $t("study.contBut") }}
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  // background-color: #e8f5ff;
  background: url("../../assets/images/v4/study/m/contBg.png"),
    linear-gradient(45deg, #e8f5ff, #ddeeff);
  background-size: (314 / 3.75vw) (314 / 3.75vw), 100% 100%;
  background-position: center (137 / 3.75vw), center;
  background-repeat: no-repeat;
  // background-color: pink;
  padding-top: (115 / 3.75vw);
  padding-bottom: (50 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  .m-p {
    padding: 0 (14 / 3.75vw);
    margin-top: (340 / 3.75vw);
  }
  .m-but2 {
    margin-top: (90 / 3.75vw);
  }
}
</style>
